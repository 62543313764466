import { navigate } from "gatsby";
import React from "react";
import { useDispatch } from "react-redux";
import { setToken } from "../../reducers/authSlice";

const ModuleDetailHeader = ({ module }) => {
  const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(setToken(null));
    navigate("/", {
      replace: true,
    });
  };

  return (
    <header>
      <nav
        className="bg-center bg-cover"
        style={{
          backgroundImage: `url(${module?.imageHeader.url})`,
        }}
      >
        <div className="container mx-auto px-6 pt-8 pb-3">
          <div className="flex justify-between items-end">
            <button className="cursor-pointer" href="#" onClick={handleSignOut}>
              <span className="text-white text-xs md:text-sm lg:text-base font-bold">
                Sign out
              </span>
            </button>
            <div className="text-xl font-semibold R-RContainer">
              <div>
                <div className="flex justify-center items-center">
                  <div className="Repro-Repur font-bold text-end">
                    Repurpose
                    <br />
                    Reprocess &#38;
                  </div>

                  <div className="flex items-stretch  text-right">
                    <div className="font-bold R-RLetters-Main shrink">R</div>
                    <div className="self-center font-bold text-5xl">+</div>
                    <div className="font-bold R-RLetters-Main shrink">R</div>
                  </div>

                  <div className="self-start text-xl">&#174;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default ModuleDetailHeader;
