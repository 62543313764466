import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import AppPage from "../components/AppPage";
import ModuleDetailHeader from "../components/modules/ModuleDetailHeader";

const ModuleDetails = ({ location }) => {
  const [playingState, setPlaying] = useState(true);
  const [videoEnded, setVideoEnded] = useState(false);
  const module = location.state ? location.state.module : null;
  // console.log(module)
  useEffect(() => {
    if (!module) {
      navigate("/main/", {
        replace: true,
      });
    } else {
    }
  }, [module]);

  const videoEndCallback = (ev) => {
    setVideoEnded(true);
    setPlaying(false);
  };

  const handlePlayClick = () => {
    setPlaying(!playingState);
  };

  const handleBackClick = () => {
    navigate("/main/", {
      replace: true,
    });
  };

  const handleQuizClick = () => {
    navigate("/quiz/", {
      replace: true,
      state: module,
    });
  };
  const quizButtonClasses = {
    primary: "px-10 py-2 font-medium text-white capitalize bg-app-title",
    secondary:
      "px-10 py-2 font-medium text-white capitalize bg-app-button-light",
  };
  return (
    <AppPage>
      <ModuleDetailHeader module={module} />
      <div className="mt-6  px-4 xl:mt-12">
        <div className="max-w-6xl  mx-auto">
          <p className="font-bold text-app-title text-center md:text-left mb-2">
            {module?.name}
          </p>
          <div className="md:flex gap-2 items-center justify-between ">
            <div className="relative place-self-start w-full md:w-2/3 group">
              <button onClick={handlePlayClick}>
                <ReactPlayer
                  url={module?.video.url}
                  width="100%"
                  height="100%"
                  playing={playingState}
                  onEnded={videoEndCallback}
                />
              </button>
              <div
                className="w-full
                  h-6 px-3
                  bg-black/0
                  -mt-7 absolute
                  opacity-0
                  duration-300 transition-opacity
                  group-hover:opacity-100
                  group-hover:bg-black/[.3]
                  "
              >
                {playingState === false ? (
                  <button onClick={handlePlayClick}>
                    <svg
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="15"
                      viewBox="0 0 24 24"
                    >
                      <path d="M3 22v-20l18 10-18 10z" />
                    </svg>
                  </button>
                ) : (
                  <button onClick={handlePlayClick}>
                    <svg
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="15"
                      viewBox="0 0 24 24"
                    >
                      <path d="M11 22h-4v-20h4v20zm6-20h-4v20h4v-20z" />
                    </svg>
                  </button>
                )}
              </div>
            </div>
            <div className="w-full md:w-1/3 place-self-start py-4 md:py-0">
              <div className="lg:max-w-lg shadow px-3 py-5 bg-app-card">
                <h3
                  className="
                    module-description
                    mt-5 mb-5
                    pb-2 md:mt-0
                    md:mb-5 font-extrabold
                    text-lg"
                >
                  Lesson:
                </h3>
                <ReactMarkdown className="text-justify text-xs module-description space-y-4">
                  {
                    module?.childContentfulModulesDescriptionTextNode
                      .description
                  }
                </ReactMarkdown>
              </div>
            </div>
          </div>
          <div className="flex justify-between py-20 md:w-2/3">
            <button
              className={quizButtonClasses.secondary}
              onClick={handleBackClick}
            >
              GO BACK
            </button>
            <button
              className={
                videoEnded
                  ? quizButtonClasses.primary
                  : quizButtonClasses.secondary
              }
              disabled={!videoEnded}
              onClick={handleQuizClick}
            >
              QUIZ
            </button>
          </div>
        </div>
      </div>
    </AppPage>
  );
};
export default ModuleDetails;
